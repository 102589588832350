import $ from 'jquery'
import BasePage from "./abstracts/base_page";

export default class ReleasesPage extends BasePage {

    constructor(props) {
        super(props);

    }

    init() {
        super.init();
    }

}